<template>
	<form class="contact-form" autocomplete="off" @submit.prevent="submit" ref="contactForm">
		<p>
			Feel free to drop me a message through the form below and I will answer as soon as I
			can.
		</p>

		<div class="robotic">
			<input
				type="text"
				name="url"
				id="url"
				tabindex="-1"
				autocomplete="off"
				placeholder="If you're human leave this blank"
				v-model="fields.url"
			/>
		</div>

		<form-input
			label="Name"
			type="text"
			name="name"
			id="name"
			required=""
			placeholder="John Doe"
			:valid="name.valid"
			v-model="fields.name"
			v-hover="'inputField'"
		/>

		<form-input
			label="E-Mail"
			type="email"
			name="email"
			id="email"
			required=""
			placeholder="email@domain.com"
			:valid="email.valid"
			v-model="fields.email"
			v-hover="'inputField'"
		/>

		<form-input
			label="Subject"
			type="text"
			name="subject"
			id="subject"
			required=""
			placeholder="Hello..."
			:valid="subject.valid"
			v-model="fields.subject"
			v-hover="'inputField'"
		/>

		<div class="form-row">
			<form-input
				label="Message"
				type="textarea"
				name="message"
				id="message"
				class="input--textarea"
				required=""
				placeholder="..."
				:valid="message.valid"
				v-model="fields.message"
				v-hover="'inputField'"
				:maxlength="message.maxlength"
			/>

			<div class="textarea-counter">
				{{ fields.message.length }} /
				{{ message.maxlength }}
			</div>
		</div>

		<base-button
			type="submit"
			variant="contact"
			:icon="button.icon"
			:text="button.text"
			ref="submitButton"
			:class="button.class"
		>
			send message
		</base-button>
	</form>
</template>

<script>
	import axios from 'axios';
	import FormInput from '@/components/FormInput.vue';
	import BaseButton from '@/components/BaseButton.vue';

	export default {
		name: 'ContactForm',
		components: {
			FormInput,
			BaseButton,
		},

		data() {
			return {
				fields: {
					name: '',
					email: '',
					subject: '',
					message: '',
					projekt8: '',
					url: '',
				},
				errors: {},
				name: {
					valid: true,
				},
				email: {
					valid: true,
				},
				subject: {
					valid: true,
				},
				message: {
					valid: true,
					maxlength: 800,
					rows: 1,
				},
				button: {
					text: 'send message',
					icon: 'envelope',
					class: '',
				},
				submitted: false,
			};
		},

		methods: {
			// submit form handler
			submit(event) {
				if (!this.submitted) {
					this.submitted = true;
					this.errors = {};

					// set button content and class
					this.button = {
						icon: 'envelope',
						text: 'sending ...',
						class: 'button--sending',
					};

					axios({
						method: 'post',
						url: '/api/contact/',
						headers: { 'content-type': 'application/json' },
						data: this.fields,
					})
						.then((response) => {
							console.log(
								`%c sending message... `,
								'background: #2c3f52; color: #ff6345'
							);

							if (response.status === 200) {
								console.log(
									`%c message sent (code: ${response.status}) `,
									'background: #2c3f52; color: #ff6345'
								);

								// set button content and class
								this.button = {
									icon: 'smile',
									text: 'thanks for your message',
									class: 'button--success',
								};

								// clear form after successful send
								Object.keys(this.fields).forEach((key, index) => {
									this.fields[key] = '';
								});
								event.target.reset();
							}
						})
						.catch((error) => {
							console.log(
								`%c an error occurred (code: ${error.response.status}) `,
								'background: #2c3f52; color: #ff6345'
							);

							// set button content and class
							this.button = {
								icon: 'skull',
								text: 'ooops an error occurred',
								class: 'button--error',
							};
						});

					// switch button text and icon back to default
					setTimeout(() => {
						this.submitted = false;
						this.button = {
							icon: 'envelope',
							text: 'send message',
							class: '',
						};
					}, 4000);
				}
			},

			// validate by type and value
			validate(type, value) {
				if (type === 'email') {
					this.email.valid = this.isEmail(value) ? true : false;
				}
			},

			// check for valid email adress
			isEmail(value) {
				// Regular expression from W3C HTML5.2 input specification:
				// https://www.w3.org/TR/html/sec-forms.html#email-state-typeemail
				const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

				return emailRegExp.test(value);
			},
		},

		watch: {
			'fields.email': function(value) {
				this.validate('email', value);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.contact-form {
		@include breakpoint($small-landscape) {
			background-color: rgba(black, 0.15);
			padding: var(--spacing-md);
		}

		@include breakpoint($medium) {
			background-color: rgba(black, 0.15);
			padding: var(--spacing-md);
		}

		@include breakpoint($large) {
			padding: var(--spacing-lg);
		}

		@include breakpoint($xlarge) {
			padding: var(--spacing-xl);
		}
	}

	.form-row {
		position: relative;
	}

	.textarea-counter {
		font-size: var(--text-sm);
		position: absolute;
		bottom: -22px;
		right: 0;
		text-align: right;
		opacity: 0.6;
	}

	.robotic {
		display: none;

		input {
			display: block;
			width: 100%;
			background: transparent;
			border: none;
		}
	}
</style>
