<template>
	<div ref="inputContainer" class="input" :class="[isValid ? 'input--valid' : 'input--error']">
		<component
			ref="inputField"
			:is="inputType"
			v-bind="$attrs"
			v-on:input="$emit('input', $event.target.value)"
			v-hover="'cta'"
			class="input__field"
			:class="`input__field--${$attrs.type}`"
		></component>
		<label :for="$attrs.name" class="input__label">
			<svg ref="inputStroke" class="input__stroke" preserveAspectRatio="none">
				<rect x="0" y="0" width="100%" height="100%" vector-effect="non-scaling-stroke" />
			</svg>
			<span class="input__label-content">
				{{ $attrs.label }}
			</span>
			<span class="input__state"></span>
		</label>
	</div>
</template>

<script>
	import debounce from 'lodash.debounce';

	export default {
		name: 'FormInput',
		inheritAttrs: false,

		data() {
			return {
				width: 0,
				height: 0,
				strokeWidth: 2000,
			};
		},

		computed: {
			isValid() {
				return this.$attrs.valid;
			},

			inputType() {
				if (this.$attrs.type === 'textarea') {
					return 'textarea';
				} else {
					return 'input';
				}
			},
		},

		watch: {
			'$attrs.value': function(value) {
				if (value !== '') {
					this.$refs.inputField.classList.add('input__field--filled');
				} else {
					this.$refs.inputField.classList.remove('input__field--filled');
				}
			},
		},

		mounted() {
			this.initStroke();

			this.resizeHandler = debounce(this.initStroke, 300);
			window.addEventListener('resize', this.resizeHandler);
		},

		destroyed() {
			window.removeEventListener('resize', this.resizeHandler);
		},

		methods: {
			initStroke() {
				this.width = this.$refs.inputContainer.clientWidth;
				this.strokeOffset = this.strokeWidth - this.width;

				this.$refs.inputStroke.style.strokeDasharray = this.strokeWidth;
				this.$refs.inputStroke.style.strokeDashoffset = this.strokeOffset;
			},
		},
	};
</script>

<style lang="scss" scoped>
	.input {
		$root: &;
		$input-height: 45px;

		position: relative;
		z-index: 1;
		display: inline-block;
		margin: var(--spacing-lg) 0 0 0;
		width: 100%;

		&__label {
			position: absolute;
			float: right;
			width: 100%;
			height: 100%;
			user-select: none;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background: var(--color-secondary-dark-40);
				opacity: 0.3;
				transform: scaleY(0);
				transform-origin: center bottom;
				transition: transform 400ms $easeInOutCubic;
				transition-delay: 300ms;
			}
		}

		&__label-content {
			position: relative;
			display: block;
			width: 100%;
			opacity: 0.6;
			font-style: italic;
			line-height: $input-height;
			transform-origin: 0% 50%;
			transition: transform 400ms $easeInOutCubic;
			transition-delay: 300ms;
		}

		&__state {
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			width: $input-height;
			height: $input-height;
			opacity: 0;
			transform: scale(0);
			transition: opacity 200ms ease-out 500ms, transform 300ms ease-out 500ms;
		}

		&--valid,
		&--error {
			#{$root}__field--filled {
				padding-right: 40px;

				& + #{$root}__label #{$root}__state {
					opacity: 1;
					transform: scale(1);
				}
			}
		}

		&--valid {
			#{$root}__field--filled {
				& + #{$root}__label #{$root}__state {
					background: url('~@/assets/svg/icon-check.svg') center center no-repeat;
					filter: brightness(0.3) sepia(1) hue-rotate(110deg) saturate(5);
				}
			}
		}

		&--error {
			#{$root}__field--filled {
				& + #{$root}__label #{$root}__state {
					background: url('~@/assets/svg/icon-cross2.svg') center center no-repeat;
					filter: brightness(0.3) sepia(1) hue-rotate(-50deg) saturate(5);
				}
			}
		}

		&__stroke {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			pointer-events: none;
			transform: scale3d(1, -1, 1);
			fill: none;
			stroke: var(--color-primary);
			stroke-width: 2px;
			stroke-dasharray: 2000;
			stroke-dashoffset: 2000;
			transition: stroke-dashoffset 800ms $easeInOutQuint;
		}

		&__field {
			position: relative;
			z-index: 2;
			display: block;
			float: right;
			width: 100%;
			height: $input-height;
			border: none;
			border-radius: 0;
			background: transparent;
			color: var(--color-white);
			font: inherit;
			-webkit-appearance: none; /* for box shadows to show on iOS */
			padding: var(--spacing-xs);

			&--textarea {
				resize: none;
				transition: height 300ms ease-out;

				& + #{$root}__label #{$root}__stroke {
					transition-duration: 1600;
				}

				&:focus,
				&.input__field--filled {
					height: 200px;
				}

				&::-webkit-scrollbar {
					background-color: transparent;
					width: 5px;
				}

				&::-webkit-scrollbar-thumb {
					background-color: var(--color-primary);
					-webkit-box-shadow: none;
				}
			}

			&:invalid {
				box-shadow: none;
				outline: 0;
			}

			&::placeholder {
				color: transparent;
			}

			&::-ms-clear {
				width: 0;
				height: 0;
			}

			&:focus,
			&--filled {
				outline: none;

				& + #{$root}__label {
					cursor: default;
					pointer-events: none;

					&:before {
						transition-delay: 0ms;
						transform: scaleY(1);
					}
				}

				& + #{$root}__label #{$root}__stroke {
					stroke-dashoffset: 0 !important;
				}

				& + #{$root}__label #{$root}__label-content {
					transition-delay: 0ms;
					transform: scale3d(0.8, 0.8, 1) translate3d(0, -#{$input-height}, 0);
				}
			}

			&:-webkit-autofill {
				&,
				&:hover,
				&:focus {
					transition: background-color 5000s ease-in-out 0s;
					-webkit-text-fill-color: var(--color-white);
				}
			}

			&.edge-autofilled {
				background-color: transparent !important;
				color: inherit !important;
			}
		}
	}
</style>
